import { createTheme, Theme } from "@mui/material/styles";

import "@fontsource/lexend";

const primary = "#14123b";

export const themeLight: Theme = createTheme({
  palette: {
    background: {
      default: "#f3f5f7",
    },
    primary: {
      main: "#2d6fcb",
    },
    secondary: {
      main: primary,
    },
  },
  typography: {
    fontFamily: ["Lexend", "helvetica", "sans-serif"].join(","),
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: primary,
          color: "white",
        },
      },
    },
  },
});
