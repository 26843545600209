import {
  Box,
  Button,
  MenuItem,
  Select,
  Skeleton,
  Typography,
} from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../core/hooks';
import { IProduct, setProduct } from '../../core/reducers/products.reducer';
import { productSelector } from '../../core/selectors/product.selector';
import { listProducts } from '../../core/actions/products.actions';

const SaveBtn = ({
  title,
  onClick,
}: {
  title: string;
  onClick: () => void;
}) => (
  <Button
    variant="outlined"
    size="small"
    onClick={onClick}
    sx={{
      p: 0,
      fontSize: '12px',
      ml: 1,
    }}
  >
    {title}
  </Button>
);

const HeaderProductSelection: FC = () => {
  const dispatch = useDispatch();
  const { selectedProduct, products, isLoading } =
    useAppSelector(productSelector);
  const [edit, setEdit] = useState<boolean>(false);
  const [currentProduct, setCurrentProduct] = useState<string | undefined>(
    selectedProduct?.id ?? '',
  );

  useEffect(() => {
    dispatch(listProducts());
  }, []);

  useEffect(() => {
    setCurrentProduct(selectedProduct?.id);
  }, [selectedProduct]);

  const handleSelectChange = (event: any) => {
    setCurrentProduct(event.target.value);
  };

  const handleApplyProduct = () => {
    dispatch(
      setProduct(
        currentProduct
          ? products.find((p: any) => p.id === currentProduct)
          : undefined,
      ),
    );
    setEdit(false);
  };

  return isLoading ? (
    <Skeleton width={200} height={50} />
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          background: edit ? '#fff' : undefined,
          borderRadius: '0 0 3px 3px',
          px: 2,
          py: 1,
          pb: edit ? undefined : 0,
          mt: '-1px',
        }}
      >
        {edit ? (
          <Select
            sx={{ width: '150px' }}
            variant="standard"
            color="primary"
            readOnly={!edit}
            labelId="select-product-label"
            id="select-product"
            value={currentProduct ?? ''}
            onChange={handleSelectChange}
            data-testid="product-select-box"
            size="small"
            // placeholder='Select product'
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {products.map((product: IProduct) => (
              <MenuItem key={product.id} value={product.id}>
                {product.name}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Box>
            <Typography color="inherit" noWrap>
              Selected Product:{' '}
              <strong>{selectedProduct?.name ?? 'None'}</strong>
            </Typography>
          </Box>
        )}

        {edit && (
          <Box>
            <SaveBtn title="Apply" onClick={() => handleApplyProduct()} />
            <SaveBtn title="Cancel" onClick={() => setEdit(false)} />
          </Box>
        )}
      </Box>
      <Box sx={{ minHeight: '18px' }}>
        {!edit && (
          <Button
            color="primary"
            size="small"
            variant="text"
            onClick={() => setEdit(true)}
            sx={{ textTransform: 'none' }}
          >
            (change)
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default HeaderProductSelection;
