import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Feature } from '../../types';
import {
  addFeature,
  addFeatureRule,
  deleteFeature,
  getFeature,
  listFeatures,
  releaseFeature,
  removeFeatureRule,
} from '../actions/features.actions';


export type Action = PayloadAction<any, string, any, any>;

export interface IFeaturesState {
  productId?: string;
  currentFeature?: Feature;
  isFeatureLoading: boolean;
  isListFeaturesLoading: boolean;
  featureFetchError?: string;
  listFeaturesError?: string;
  features: Feature[];
  lastAction?: Action;
}

const initialState: IFeaturesState = {
  productId: undefined,
  isFeatureLoading: false,
  isListFeaturesLoading: false,
  listFeaturesError: undefined,
  featureFetchError: undefined,
  features: [],
};

const intialFeature: Feature = {
  id: '',
  name: '',
  allowedDomains: [],
  excludedDomains: [],
  allowedUsers: [],
  excludedUsers: [],
};

export const featureSlice = createSlice({
  name: 'features',
  initialState: { ...initialState },
  reducers: {
    resetFeature(state: IFeaturesState) {
      state.currentFeature = undefined;
    }
  },
  extraReducers: (builder) => {
    //listFeatures
    builder.addCase(
      listFeatures.fulfilled,
      (state: IFeaturesState, action: any) => {
        state.isListFeaturesLoading = false;
        state.listFeaturesError = undefined;
        state.features = action.payload;
        state.lastAction = action;
      },
    );
    builder.addCase(
      listFeatures.pending,
      (state: IFeaturesState, action: any) => {
        state.isListFeaturesLoading = true;
        state.listFeaturesError = undefined;
        state.features = [];
        state.lastAction = action;
      },
    );
    builder.addCase(
      listFeatures.rejected,
      (state: IFeaturesState, action: any) => {
        state.isListFeaturesLoading = false;
        state.listFeaturesError = action.payload;
        state.lastAction = action;
      },
    );
    //getFeature
    builder.addCase(
      getFeature.fulfilled,
      (state: IFeaturesState, action: any) => {
        state.isFeatureLoading = false;
        state.featureFetchError = undefined;
        state.currentFeature = action.payload;
        state.lastAction = action;
      },
    );
    builder.addCase(
      getFeature.pending,
      (state: IFeaturesState, action:any) => {
        state.isFeatureLoading = true;
        state.featureFetchError = undefined;
        state.currentFeature = intialFeature;
        state.lastAction = action;
      },
    );
    builder.addCase(
      getFeature.rejected,
      (state: IFeaturesState, action: any) => {
        state.isFeatureLoading = false;
        state.featureFetchError = action.payload;
        state.lastAction = action;
      },
    );
    //addFeature
    builder.addCase(
      addFeature.fulfilled,
      (state: IFeaturesState, action: any) => {
        state.isFeatureLoading = false;
        state.featureFetchError = undefined;
        state.currentFeature = action.payload;
        state.lastAction = action;
      },
    );
    builder.addCase(
      addFeature.pending,
      (state: IFeaturesState, action: any) => {
        state.isFeatureLoading = true;
        state.featureFetchError = undefined;
        state.lastAction = action;
      },
    );
    builder.addCase(
      addFeature.rejected,
      (state: IFeaturesState, action: any) => {
        state.isFeatureLoading = false;
        state.featureFetchError = action.payload;
        state.lastAction = action;
      },
    );
    //addRule
    builder.addCase(
      addFeatureRule.fulfilled,
      (state: IFeaturesState, action: any) => {
        state.isFeatureLoading = false;
        state.featureFetchError = undefined;
        state.lastAction = action;
      },
    );
    builder.addCase(
      addFeatureRule.pending,
      (state: IFeaturesState, action: any) => {
        state.isFeatureLoading = true;
        state.featureFetchError = undefined;
        state.lastAction = action;
      },
    );
    builder.addCase(
      addFeatureRule.rejected,
      (state: IFeaturesState, action: any) => {
        state.isFeatureLoading = false;
        state.featureFetchError = action.payload;
        state.lastAction = action;
      },
    );
    //removeRule
    builder.addCase(
      removeFeatureRule.fulfilled,
      (state: IFeaturesState, action: any) => {
        state.isFeatureLoading = false;
        state.featureFetchError = undefined;
        state.lastAction = action;
      },
    );
    builder.addCase(
      removeFeatureRule.pending,
      (state: IFeaturesState, action:any) => {
        state.isFeatureLoading = true;
        state.featureFetchError = undefined;
        state.lastAction = action;
      },
    );
    builder.addCase(
      removeFeatureRule.rejected,
      (state: IFeaturesState, action: any) => {
        state.isFeatureLoading = false;
        state.featureFetchError = action.payload;
        state.lastAction = action;
      },
    );
    //release
    builder.addCase(
      releaseFeature.fulfilled,
      (state: IFeaturesState, action: any) => {
        state.isFeatureLoading = false;
        state.featureFetchError = undefined;
        state.lastAction = action;
      },
    );
    builder.addCase(
      releaseFeature.pending,
      (state: IFeaturesState, action: any) => {
        state.isFeatureLoading = true;
        state.featureFetchError = undefined;
        state.lastAction = action;
      },
    );
    builder.addCase(
      releaseFeature.rejected,
      (state: IFeaturesState, action: any) => {
        state.isFeatureLoading = false;
        state.featureFetchError = action.payload;
        state.lastAction = action;
      },
    );
    //delete
    builder.addCase(
      deleteFeature.fulfilled,
      (state: IFeaturesState, action: any) => {
        state.isFeatureLoading = false;
        state.featureFetchError = undefined;
        state.currentFeature = undefined;
        state.lastAction = action;
      },
    );
    builder.addCase(
      deleteFeature.pending,
      (state: IFeaturesState, action: any) => {
        state.isFeatureLoading = true;
        state.featureFetchError = undefined;
        state.lastAction = action;
      },
    );
    builder.addCase(
      deleteFeature.rejected,
      (state: IFeaturesState, action: any) => {
        state.isFeatureLoading = false;
        state.featureFetchError = action.payload;
        state.lastAction = action;
        state.currentFeature = undefined;
      },
    );
  },
});

export const { resetFeature } = featureSlice.actions;
export default featureSlice.reducer;
