import { withAuthenticationRequired } from '@auth0/auth0-react';
import { AppBar, Box, Skeleton } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { useSSOAuthentification } from '../../core/auth0/useSSOAuthentication';
import logo from '../../images/logos/norstella-logo.svg';
import AccountMenu from '../AccountMenu/AccountMenu';
import HeaderProductSelection from '../HeaderProductSelection/HeaderProductSelection';
import Main from '../Main/Main';
import SnackBarNotification from '../SnackBarNotification/SnackBarNotification';

const BaseLayout: FC = () => {

  const { isLoading } = useSSOAuthentification(false);

  const [open, setOpen] = React.useState(false);

  return isLoading ? (
    <Skeleton
      variant="rectangular"
      animation="wave"
      height={1024}
      width="100%"
    />
  ) : (
    <div className="flex">
      <CssBaseline />
      <AppBar
        position="fixed"
        color="secondary"
        data-testid="appbar"
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
            }}
          >
            <a href="/" rel="noopener noreferrer" className="flex">
              <img
                src={logo}
                style={{ width: '120px' }}
                alt="Norstella Logo"
                data-testid="pharma-intelligence-logo"
              />
            </a>
          </Box>

          <HeaderProductSelection />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
            }}
          >
            <Typography component="h1" variant="h6" color="inherit" noWrap>
              Features management tool
            </Typography>
            <AccountMenu />
          </Box>
        </Toolbar>
      </AppBar>

      <Main>
        <Outlet />
      </Main>
      <SnackBarNotification />
    </div>
  );
};

export default withAuthenticationRequired(BaseLayout);
