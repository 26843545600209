import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { listProducts } from '../actions/products.actions';

const storedProduct = localStorage.getItem('product');

export interface IProduct {
  id: string;
  name: string;
}

export interface IProductsState {
  products?: any;
  error?: Error | string;
  isLoading: boolean;
  selectedProduct?: IProduct;
}

const initialState: IProductsState = {
  products: [],
  error: undefined,
  isLoading: false,
  selectedProduct: storedProduct ? JSON.parse(storedProduct) : undefined,
};

export const productsSlice = createSlice({
  name: 'products',
  initialState: { ...initialState },
  reducers: {
    setProduct(state, action: any) {
      if (action.payload) {
        state.selectedProduct = action.payload;
        localStorage.setItem('product', JSON.stringify(action.payload));
      } else {
        state.selectedProduct = undefined;
        localStorage.removeItem('product');
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      listProducts.fulfilled,
      (state: IProductsState, action: PayloadAction<IProduct[]>) => {
        state.isLoading = false;
        state.error = undefined;
        state.products = action.payload;
      },
    );
    builder.addCase(listProducts.pending, (state: IProductsState, _: any) => {
      state.isLoading = true;
      state.error = undefined;
      state.products = [];
    });
    builder.addCase(
      listProducts.rejected,
      (state: IProductsState, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      },
    );
  },
});

export const { setProduct } = productsSlice.actions;
export default productsSlice.reducer;
