import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../api';

export type BaseFeatureActionPayload = { productId: string; featureId: string };

export type AddFeaturePayload = {
  productId: string;
  name: string;
};

export type AddFeatureRulePayload = {
  productId: string;
  featureId: string;
  type: 'user' | 'domain';
  action: 'allow' | 'exclude';
  value: string;
};

export type RemoveFeatureRulePayload = {
  productId: string;
  featureId: string;
  type: 'user' | 'domain';
  action: 'allow' | 'exclude';
  ruleId: string;
};

export type ReleaseFeaturePayload = {
  productId: string;
  featureId: string;
  action: 'release' | 'unrelease';
}

export const listFeatures = createAsyncThunk(
  'features/list',
  async (productId: string, thunkAPI) => {
    try {
      const response = await api.features.getFeatures(productId);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  },
);

export const getFeature = createAsyncThunk(
  'features/get',
  async (payload: BaseFeatureActionPayload, thunkAPI) => {
    try {
      const response = await api.features.getFeature(
        payload.productId,
        payload.featureId,
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  },
);

export const addFeature = createAsyncThunk(
  'features/add',
  async (payload: AddFeaturePayload, thunkAPI) => {
    try {
      const response = await api.features.addFeature(
        payload.productId,
        payload.name
      );
      thunkAPI.dispatch(listFeatures(payload.productId));
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  },
);

export const addFeatureRule = createAsyncThunk(
  'features/addRule',
  async (payload: AddFeatureRulePayload, thunkAPI) => {
    try {
      const response = await api.features.addFeatureRule(
        payload.productId,
        payload.featureId,
        payload.type,
        payload.action,
        payload.value,
      );
      thunkAPI.dispatch(
        getFeature({
          productId: payload.productId,
          featureId: payload.featureId,
        }),
      );
      thunkAPI.dispatch(listFeatures(payload.productId));
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  },
);

export const removeFeatureRule = createAsyncThunk(
  'features/removeRule',
  async (payload: RemoveFeatureRulePayload, thunkAPI) => {
    try {
      const response = await api.features.removeFeatureRule(
        payload.productId,
        payload.featureId,
        payload.type,
        payload.action,
        payload.ruleId,
      );
      thunkAPI.dispatch(
        getFeature({
          productId: payload.productId,
          featureId: payload.featureId,
        }),
      );
      thunkAPI.dispatch(listFeatures(payload.productId));
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  },
);

export const releaseFeature = createAsyncThunk(
  'features/release',
  async (payload: ReleaseFeaturePayload, thunkAPI) => {
    try {
      const response = await api.features.releaseFeature(
        payload.productId,
        payload.featureId,
        payload.action,
      );
      thunkAPI.dispatch(
        getFeature({
          productId: payload.productId,
          featureId: payload.featureId,
        }),
      );
      thunkAPI.dispatch(listFeatures(payload.productId));
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const deleteFeature = createAsyncThunk(
  'features/delete',
  async (payload: BaseFeatureActionPayload, thunkAPI) => {
    try {
      const response = await api.features.deleteFeature(
        payload.productId,
        payload.featureId,
      );
      thunkAPI.dispatch(listFeatures(payload.productId));
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

