import { combineReducers } from 'redux';
import authReducer from './auth.reducer';
import featuresReducer from './features.reducer';
import notificationsReducer from './noticications.reducer';
import productsReducer from './products.reducer';

const rootReducer = combineReducers({
  productsData: productsReducer,
  authData: authReducer,
  notificationsData: notificationsReducer,
  featuresData: featuresReducer
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;