import { withAuthenticationRequired } from '@auth0/auth0-react';
import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Forbidden from './components/AuthComponents/Forbidden';
import BaseLayout from './components/BaseLayout/BaseLayout';

const FeatureManagement = withAuthenticationRequired(
  lazy(() => import('./components/FeatureManagement/FeatureManagement')),
);

const App = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<BaseLayout />}>
          <Route index element={<FeatureManagement />} />
        </Route>
        <Route path="forbidden" element={<Forbidden />} />
      </Routes>
    </Suspense>
  );
};

export default App;
