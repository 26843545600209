import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../api';

export const listProducts = createAsyncThunk(
  'products/list',
  async (_, thunkAPI) => {
    try {
      const response = await api.products.getProducts();
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  },
);
