import axios from 'axios';
import { endpoints } from './endpoints';
import { Feature } from '../../types';

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error.response.data);
  },
);

export const api = {
  products: {
    getProducts: async () => {
      return await request('get', endpoints.getProducts());
    },
  },
  features: {
    getFeatures: async (productId: string) => {
      return await request('get', endpoints.getFeatures(productId));
    },
    getFeature: async (productId: string, featureId: string) => {
      return await request('get', endpoints.getFeature(productId, featureId));
    },
    addFeature: async (productId: string, featureName: string) => {
      return await request('post', endpoints.addFeature(productId), {
        name: featureName,
      });
    },
    updateFeature: async (
      productId: string,
      featureId: number,
      feature: Feature,
    ) => {
      return await request(
        'put',
        endpoints.updateFeature(productId, featureId),
        { ...feature },
      );
    },
    addFeatureRule: async (
      productId: string,
      featureId: string,
      type: 'user' | 'domain',
      action: 'allow' | 'exclude',
      value: string,
    ) => {
      return await request(
        'post',
        endpoints.addFeatureRule(productId, featureId, type, action),
        { [type]: value },
      );
    },
    removeFeatureRule: async (
      productId: string,
      featureId: string,
      type: 'user' | 'domain',
      action: 'allow' | 'exclude',
      ruleId: string,
    ) => {
      return await request(
        'delete',
        endpoints.removeFeatureRule(productId, featureId, type, action, ruleId),
      );
    },
    releaseFeature: async (
      productId: string,
      featureId: string,
      action: 'release' | 'unrelease',
    ) => {
      return await request(
        'put',
        endpoints.releaseFeature(productId, featureId, action),
      );
    },
    deleteFeature: async (productId: string, featureId: string) => {
      return await request(
        'delete',
        endpoints.deleteFeature(productId, featureId),
      );
    },
  },
};

export type MethodType = 'post' | 'get' | 'put' | 'delete';

const request = async (method: MethodType, url: string, data: any = null) => {
  const accessToken = localStorage.getItem('token');

  return await axios({
    method,
    url,
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
