import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { allowedAccessUserList, getMyProfileLink } from "./authorizationConfig";
import { User } from "./User";
import { getUser } from "./auth.service";
import { loginSuccess } from "../actions/auth.actions";

export const useSSOAuthentification = (
  withoutCache: boolean
): {
  isLoading: boolean;
} => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isVerifyTokenInProcess, setIsVerifyTokenInProgress] =
    useState<boolean>(true);

  const {
    getAccessTokenSilently,
    isLoading,
    logout,
    getIdTokenClaims,
    getAccessTokenWithPopup,
  } = useAuth0();

  const isRegistrationCompleted = useCallback(async (): Promise<boolean> => {
    const user = await getIdTokenClaims();
    const isEmailVerified = user?.email_verified ?? true;
    if (!isEmailVerified) {
      logout();
      return false;
    }
    const isSignupCompletion = user?.registration_completed;

    if (!isSignupCompletion) {
      window.location.replace(getMyProfileLink());
      return false;
    }
    return true;
  }, [getIdTokenClaims, logout]);

  const retrieveAccessToken = useCallback(async () => {
    try {
      return await getAccessTokenSilently(
        withoutCache ? { cacheMode: "off" } : {}
      );
    } catch (e) {
      console.error(e);
      return getAccessTokenWithPopup(withoutCache ? { cacheMode: "off" } : {});
    }
  }, [getAccessTokenSilently, getAccessTokenWithPopup, withoutCache]);

  const getAccessToken = useCallback(async (): Promise<void> => {
    try {
      setIsVerifyTokenInProgress(true);
      const isUserConfirmedRegistration = await isRegistrationCompleted();

      if (!isUserConfirmedRegistration) {
        localStorage.removeItem("token");
        return;
      }

      const token = await retrieveAccessToken();

      localStorage.setItem("token", token ?? "");

      const userInfo = (await getUser(token)) as User;
      if (
        // Temp disabled
        // !allowedAccessUserList.some((user: string) => user === userInfo.email.toLowerCase()) &&
        false &&
        !window.location.pathname.includes("forbidden")
      ) {
        navigate("forbidden");
        return;
      }

      dispatch(loginSuccess({ ...userInfo, features: [] }));
    } catch (e) {
      if (
        (e as any).error === "missing_refresh_token" ||
        (e as any).error === "invalid_grant" ||
        (e as any).error === "ID token is required but missing"
      ) {
        logout();
      }

      console.error(e);
    } finally {
      setIsVerifyTokenInProgress(false);
    }
  }, [
    dispatch,
    retrieveAccessToken,
    logout,
    navigate,
    isRegistrationCompleted,
  ]);

  useEffect(() => {
    getAccessToken();
  }, []);

  return {
    isLoading: isLoading || isVerifyTokenInProcess,
  };
};
