export function environment() {
  if (!window || !window.location) {
    return 'dev';
  }
  const hostname = window.location.hostname;

  if (!hostname || hostname === 'localhost' || hostname.indexOf('.dev.') > 0) {
    return 'dev';
  }

  if (hostname.indexOf('.test.') > 0) {
    return 'test';
  }

  if (hostname.indexOf('.uat.') > 0) {
    return 'uat';
  }

  return 'prd';
}

export function apiToUse() {
  const apiToUse = process.env.REACT_APP_API ?? '';
  const availableEnvs = ['prd', 'test', 'dev', 'local', 'uat'];

  if (availableEnvs.includes(apiToUse)) return apiToUse;

  return environment();
}

export const getDefaultEndpoint = (): string => {
  const apiEnv = apiToUse();

  switch (apiEnv) {
    case 'local':
      return 'https://featuresflag-api.dev.nxp.norstella-labs.com';

    case 'dev':
      return 'https://featuresflag-api.dev.nxp.norstella-labs.com';

    case 'test':
      return 'https://featuresflag-api.test.nxp.norstella-labs.com';

    case 'uat':
      return 'https://featuresflag-api.uat.nxp.norstella-labs.com';

    case 'prd':
      return 'https://featuresflag-api.nxp.norstella.com';

    default:
      return `https://featuresflag-api.dev.nxp.norstella-labs.com`;
  }
};

export const endpoints = {
  getProducts() {
    return `${getDefaultEndpoint()}/products`;
  },
  getFeatures(productId: string) {
    return `${getDefaultEndpoint()}/products/${productId}/features`;
  },
  getFeature(productId: string, featureId: string) {
    return `${getDefaultEndpoint()}/products/${productId}/features/${featureId}`;
  },
  addFeature(productId: string) {
    return `${getDefaultEndpoint()}/products/${productId}/features`;
  },
  updateFeature(productId: string, featureId: number) {
    return `${getDefaultEndpoint()}/products/${productId}/features/${featureId}`;
  },
  addFeatureRule(
    productId: string,
    featureId: string,
    type: 'user' | 'domain',
    action: 'allow' | 'exclude',
  ) {
    return `${getDefaultEndpoint()}/products/${productId}/features/${featureId}/${type}/${action}`;
  },
  removeFeatureRule(
    productId: string,
    featureId: string,
    type: 'user' | 'domain',
    action: 'allow' | 'exclude',
    ruleId: string,
  ) {
    return `${getDefaultEndpoint()}/products/${productId}/features/${featureId}/${type}/${action}/${ruleId}`;
  },
  releaseFeature(
    productId: string,
    featureId: string,
    action: 'release' | 'unrelease',
  ) {
    return `${getDefaultEndpoint()}/products/${productId}/features/${featureId}/${action}`;
  },
  deleteFeature(productId: string, featureId: string) {
    return `${getDefaultEndpoint()}/products/${productId}/features/${featureId}`;
  },
  getAllUsers(productId: string, filter: string) {
    return `${getDefaultEndpoint()}/project/${productId}/users?filter=${filter}`;
  },
  getUserFeatures(productId: string, userId: number) {
    return `${getDefaultEndpoint()}/project/${productId}/user/${userId}/features`;
  },
};
